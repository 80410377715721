import React from "react"
import { Box } from "@material-ui/core";
import BottomNav from "./components/BottomNav";

export default function Layout(props) {
  return (
    <main style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Box sx={{ flexGrow: 1, my: 8 }}>
        {props.children}
      </Box>
      <BottomNav />
    </main>
  )
}