export const txt = {
  continue_to_scoresheet: "Continue to Scoresheet",
  log_in: "Log in",
  login_below_text: "Enter your email address and password.",
  email_contact: "Email / Contact No.",
  password: "Password",
  forget_password: "Forgot Password",
  login: "Login",
  or_continue_with: "or continue with",
  new_user_register: "New user? Register",
  enter_the_OTP_sent_to_the_number_below:
    " Enter the OTP sent to the number below",
  enter_phone_number_for_get_new_password:
    "Enter Phone number for get new password",
  register: "Register",
  register_below_txt: "Enter your email address and password.",
  your_name: "Your Name",
  first_name: "First Name",
  last_name: "Last Name",
  email_address: "Email Address",
  c_password: "Confirm Password",
  already_register: "Alredy Register ? Login",
  My_Profile: "My Profile",
  Gender: "Gender",

  number_verification: "Number Verification",
  num_verify_below_txt: "Enter the OTP sent to below number",
  phone_number: "Phone Number",
  dont_receive_code: "You didn’t Receive code? Resend in 0s",
  donot_receive_code: "You didn’t Receive code?",
  enter_otp: "Enter Otp",
  next: "Next",
  send_otp: "Send Otp",
  resend_otp: "Resend",
  Edit_My_Profile: "Edit My Profile",

  create_new_password: "Create New Password",
  enter_new_password: "Enter New Password",
  save: "Save",

  player_firstname: "Player First Name",
  player_lastname: "Player Last Name",
  enter_players_name_and_phone_number: "Enter player’s name and phone number",
  continue: "Continue",

  add_another_player: "Add Another Player",
  player_creation_successful: "Player Creation Successful!",
  view_players: "View Players",
  Players: "Players",
  Matches: "Matches",
  Statistics: "Statistics",
  Suggestions: "Suggestions",
  Leaderboard: "Leaderboard",

  create_new: "Create New",
  player: "Player",
  Create_a_New_Player: "Create a New Player",
  team: "Team",
  Create_New_Team: "Create New Team",
  Leaderboards: "Leaderboards",
  match: "Match",
  Create_New_Match: "Create New Match",
  tournament: "Tournament",
  Create_New_Tournament: "Create New Tournament",
  upcoming_matches: "Upcoming Matches",
  no_matches: "No Matches",
  Settings: "Settings",
  Logout: "Logout",
  ShareTheApp: "Share The App",
  Matches: "Matches",
  Runs: "Runs",
  Wickets: "Wickets",
  Teams: "Teams",
  Statistics: "Statistics",

  create_team: "Create Team",
  start_by_entering_the_details_of_your_team: "Start by entering the details of your team.",

  add_player: "Add Player",
  add_players: "Add Players",
  copy_link: "Copy Link",
  add_more_player: "Add More Players",
  create_players: "Create Players",
  players_of_your_team_will_be_listed_here: "Players of your team will be listed here.",
  team_name: "Team Name",
  location: "Location",

  view_all_player: "View All Player",
  view_all_teams: "View All Teams",
  view_all_matches : "View All Matches",
  view_all_tournaments : "View All Tournaments",
  select_a_player_to_verify_their_phone_number_to_match_with_the_team: "Select a player to verify their phone number to match with the team.",
  below_are_the_information_about: "Below are the information about the player for your preview.",

  select_or_invite_or_create_players_to_your_team: "Select or invite or create players to your team.",
  verify_and_add_players_to_your_team: "Verify and add players to your team ",
  verify_player: "Verify Player",
  enter_last_4_digits_of_the_players_mobile_number: "Enter last 4 digit’s of the player’s Mobile Number.",
  verification_successful: "Verification Successful!",

  created_Successfully: "Created Successfully!",
  are_you_sure_you_want_to_remove_this_player: "Are you sure you want to remove this player?",
  yes: "Yes", no: "No",

  select_your_team: "Select Your Team",
  select_an_existing_team_or_create_a_new_team: "Select an existing team or create a new team.",
  search_team: "Search Team",
  my_teams: "My teams",
  Personal_Details: "Personal Details",
  Preferences: "Preferences",
  Payments: "Payments",
  Connections: "Connections",
  previous: "Previous",
  global: "Global",
  rac_uptown: "RAC Uptown",

  created_Successfully: "Created Successfully!",
  are_you_sure_you_want_to_remove_this_player: "Are you sure you want to remove this player?",
  yes: "Yes", no: "No",

  invalid_last_4_digits: "Invalid last 4 digits.",

  create_a_match: "Create a Match",
  update_a_match: "Update a Match",
  start_by_entering_the_details_of_your_match: "Start By Entering the details of your match.",
  match_name: "Match Name",
  date: "Date",
  Date_of_Birth: "Date of Birth",
  time: "Time",
  select_your_team: "Select Your Team",
  select_opposition_team: "Select Opposition Team",
  enter_match_name: "Enter Match Name",
  enter_ground_name: "Enter Ground Name",
  enter_match_date: "Enter Match Date",
  enter_match_time: "Enter Match Time",
  create_match: "Create Match",
  update_match: "Update Match",
  Update: "Update",
  confirm_team: "Confirm Team",
  selected_successfully: "Selected Successfully",
  confirm_the_players_to_select_and_finalize_your_team: "Confirm the players to select and finalize your team",
  match_type: "Match Type",
  number_of_overs: "Number of Overs",
  ball_type: "Ball Type",
  pitch_type: "Pitch Type",
  select_the_desired_match_type: "Select the desired match type.",
  select_the_desired_ball_type: "Select the desired ball type.",
  select_the_desired_pitch_type: "Select the desired pitch type.",
  add_as_per_your_requirement: "Add as per your requirement",
  confirm: "Confirm",
  successfully_created_a_match: "Successfully created a match.",
  successfully_update_a_match: "Successfully update a match.",
  my_matches: "My Matches",
  today: "Today",
  upcoming: "Upcoming",
  results: "Results",
  ground: "Ground",

  match_details: "Match Details",
  analysis: "Analysis",
  recommendations: "Recommendations",
  toss_won_by: "Toss Won By",
  choose_to: "Choose To",
  bat: "Bat",
  field: "Field",

  wicket: "Wicket",
  select_the_dismissal_type: "Select the dismissal type.",
  next_batter: "Next Better",
  pick_the_next_batter: "Pick the next batter",
  next_bowler: "Next Bowler",
  Contact_Number: "Contact Number",
  Gender: "Gender",
  Male: "Male",
  Female: "Female",
  Prefer_Not_To_Say: "Prefer Not To Say",
  Playing_Role: "Playing Role",
  Batting_Style: "Batting Style",
  Batting: "Batting",
  Bowling: "Bowling",
  Fielding: "Fielding",
  Captain: "Captain",
  Bowling_Style: "Bowling Style",
  pick_the_next_bowler: "Pick the next bowler",

  confirm_your_particiaption: "Confirm Your Particiaption",
  are_you_sure_you_want_to_delete_this_match: "Are you sure you want to delete this match?",
  select_the_desired_option: "Select the desired option..",

  // error msg
  please_enter_first_name: "Please Enter First Name",
  please_enter_last_name: "Please Enter Last Name",
  please_enter_location: "Please Enter Location",
  please_enter_date_of_birth: "Please Enter Date of Birth",
  Please_select_playing_role: "Please select playing role",
  Please_select_batting_style: "Please select batting style",
  Please_select_bowling_style: "Please select bowling style",
  please_enter_valid_email_address: "Please Enter Valid Email Address",
  please_enter_valid_contact_number: "Please Enter Valid Contact Number",
  password_cant_be_empty: "Password can't be empty",
  confirm_password_cant_be_empty: "Confirm Password can't be empty",
  password_invalid_format:
    "Password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character",
  passwords_doesnt_match: "Password Doesn't Match",
  invalid_otp: "Invalid Otp",
  location_required: "Location*",

  // Toast msg
  otp_sent: "OTP has been sent to your number",
  welcome: "Welcome back ",
  custom_notification: "This is a custom toast notification!",
  OTP_has_been_sent_to_your_number: "OTP has been sent to your number",
  enter_your_email_or_your_phone_number:
    "Enter your email or your phone number",
  enter_password: "Enter Password",
  new_password_set_successfully: "New Password Set Succeessfully",
};
