import React, { useContext } from "react";
import PlayerCricketHistoryProfile from "../Player/PlayerCricketHistoryProfile";
import { AuthContext } from "../../context/AuthContext";

const MyCricket = () => {

  const { userInfo } = useContext(AuthContext);

  return (
    <div className="pageMainContainer">
      <PlayerCricketHistoryProfile pageTitle={'My Cricket'} userInfo={userInfo} />
    </div>
  );
};
export default MyCricket;
